<div class="header-container" >
  <div class="logo-container">
    
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    
    <a class="logo" href="#" (click)="navigateHome()">Intercept</a>
  </div>

  <!--
    <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
      <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
    </nb-select>
  -->

</div>

<div class="header-container">
  <nb-actions size="small">
    <nb-action class="user-action" *nbIsGranted="['view', 'user']" >
      <nb-user 
        [nbContextMenu]="userMenu"
        nbContextMenuTag="login"
        [onlyPicture]="false"
        title="Administrador"
        [name]="user.name"
        [showTitle]="true"
        [picture]="base64image">
      </nb-user>
    </nb-action>
  </nb-actions>
</div>
